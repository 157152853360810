<template>
  <time-card v-if="currentUser" :time-card-id="id" :user="currentUser" :admin-mode="false" />
  <loading-overlay v-else />
</template>

<script>
import { CurrentUserService } from '@/common/services/current_user.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import TimeCard from '@/common/components/time-card.vue';

export default {
  name: 'TimeCardEdit',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
    TimeCard,
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      currentUser: null,
    };
  },
  created() {
    CurrentUserService.getCurrentUser().then(({ data }) => {
      this.currentUser = data;
    });
  },
};

</script>

<style lang="scss">
</style>
