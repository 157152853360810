import JwtService from './jwt.service';
import { UserService } from './user.service';

const token = JwtService.parseJwt();

export const CurrentUserService = {
  getCurrentUser() {
    return UserService.get(token.id);
  },
};

export default CurrentUserService;
